import { post } from '@/utils/request';

// 门店列表
const gethierListReq = () => post({
  url: '/store/app.Home/getMerchantList',
});

// 会员简报
const userSimpleReportReq = (params) => post({
  url: '/store/statistical.User/userSimpleReport',
  data: {
    ...params,
  },
});

// 订单简报
const getBriefReq = (params) => post({
  url: '/store/statistical.Order/brief',
  data: {
    ...params,
  },
});

// 活动简报
const getActiveBriefRef = (params) => post({
  url: '/store/statistical.Activity/brief',
  data: {
    ...params,
  },
});

// 获取时间
const getDefaultReq = () => post({
  url: '/store/common.Time/getDefult',
});

// 设置时间
const setDefultReq = (time_type) => post({
  url: '/store/common.Time/setDefult',
  data: {
    time_type,
  },
});

// 会员增长
const userGrowthReq = (params) => post({
  url: '/store/statistical.User/userGrowth',
  data: {
    ...params,
  },
});

// 订单趋势
const trendReq = (params) => post({
  url: '/store/statistical.Order/trend',
  data: {
    ...params,
  },
});

// 会员分配跟进
const userDistrAndFollowReq = (params) => post({
  url: '/store/statistical.User/userDistrAndFollow',
  data: {
    ...params,
  },
});

// 热销分类
const hotTypeReq = (params) => post({
  url: '/store/statistical.Order/hotType',
  data: {
    ...params,
  },
});
// 会员转换
const userConvertReq = (params) => post({
  url: '/store/statistical.User/userConvert',
  data: {
    ...params,
  },
});

// 热销材质
const hotMaterialReq = (params) => post({
  url: '/store/statistical.Order/hotMaterial',
  data: {
    ...params,
  },
});

// 会员来源
const userSourceReq = (params) => post({
  url: '/store/statistical.User/userSource',
  data: {
    ...params,
  },
});

// 优惠分析
const discountReq = (params) => post({
  url: '/store/statistical.Order/discount',
  data: {
    ...params,
  },
});

// 会员等级
const userLevelReq = (params) => post({
  url: '/store/statistical.User/userLevel',
  data: {
    ...params,
  },
});

// 工具参与度
const toolJoinReq = (params) => post({
  url: '/store/statistical.Activity/toolJoin',
  data: {
    ...params,
  },
});

// 活动浏览趋势
const visitTrendReq = (params) => post({
  url: '/store/statistical.Activity/visitTrend',
  data: {
    ...params,
  },
});

// 活动参与趋势
const joinTrendReq = (params) => post({
  url: '/store/statistical.Activity/joinTrend',
  data: {
    ...params,
  },
});

// 活动引流趋势
const attractTrendReq = (params) => post({
  url: '/store/statistical.Activity/attractTrend',
  data: {
    ...params,
  },
});

// 会员转化趋势
const conversionTrendReq = (params) => post({
  url: '/store/statistical.Activity/conversionTrend',
  data: {
    ...params,
  },
});

// 库存简报
const getStockReportReq = (params) => post({
  url: '/store/statistical.Stock/brief',
  data: {
    ...params,
  },
});

// 库存品类数量
const stockTypeReq = (params) => post({
  url: '/store/statistical.Stock/stockType',
  data: {
    ...params,
  },
});

// 滞销品类数量
const unsalableTypeReq = (params) => post({
  url: '/store/statistical.Stock/unsalableType',
  data: {
    ...params,
  },
});

// 材质成色
const stockMaterialReq = (params) => post({
  url: '/store/statistical.Stock/stockMaterial',
  data: {
    ...params,
  },
});

// 款式成色
const stockPatternReq = (params) => post({
  url: '/store/statistical.Stock/stockPattern',
  data: {
    ...params,
  },
});

export {
  stockPatternReq,
  stockMaterialReq,
  unsalableTypeReq,
  stockTypeReq,
  getStockReportReq,
  gethierListReq,
  userSimpleReportReq,
  getDefaultReq,
  setDefultReq,
  userGrowthReq,
  userDistrAndFollowReq,
  userConvertReq,
  userSourceReq,
  userLevelReq,
  getBriefReq,
  trendReq,
  hotTypeReq,
  hotMaterialReq,
  discountReq,
  getActiveBriefRef,
  toolJoinReq,
  visitTrendReq,
  joinTrendReq,
  attractTrendReq,
  conversionTrendReq,
};
