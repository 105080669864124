<template>
  <div class="table-wrapper">
    <div class="card" style="background: white;display: flex;">
      <div class="left" @click="goBack">
        <i class="el-icon-back"></i>
        <div>返回</div>
      </div>
      <div class="right">
        <span style="margin-right: 10px;">{{title}}</span>
        <el-tag type="info" class="tag">{{store.hierarchy_name}}</el-tag>
        <el-tag type="info" class="tag">
          <span v-if="time.field === 'times'">
            {{time.title[0]}}
            <span style="margin: 0 10px;">至</span>
            {{time.title[1]}}
          </span>
          <span v-else>{{time.title}}</span>
        </el-tag>
        <el-button type="primary" @click="handleExport" plain icon="el-icon-upload2" :loading="exportLoading" style="margin-left: auto;">导出excel</el-button>
      </div>
    </div>
    <div class="tab" v-if="tabs.length">
      <el-tabs v-model="type" @tab-click="handleClick">
        <template v-for="item in tabs">
          <el-tab-pane :label="item.label" :name="item.name" :key="item.name"></el-tab-pane>
        </template>
      </el-tabs>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        :height="height"
        stripe
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width">
            <template slot-scope="scope">
              {{(scope.row[item.field_alias] || scope.row[item.field_alias] === 0)? scope.row[item.field_alias] : '--'}}
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[15, 30, 40, 50]"
        :page-size="15"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request';

export default {
  data() {
    return {
      height: 720,
      type: '1',
      page: 1,
      limit: 15,
      tableData: {
        list: [],
        total: 0,
      },
      exportLoading: false,
      loading: false,
    };
  },
  props: {
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    exportUrl2: {
      type: String,
      default: '',
    },
    url2: {
      type: String,
      default: '',
    },
    exportUrl: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '会员增长',
    },
    store: {
      type: Object,
      default() {
        return {
          hierarchy_name: '美琳珠宝水贝店',
          hierarchy_id: 5,
        };
      },
    },
    time: {
      type: Object,
      default() {
        return {
          title: '本月',
          field: 'month',
        };
      },
    },
    rowList1: {
      type: Array,
      default() {
        return [
          { field_text: '序号', field_alias: 'index' },
          { field_text: '日期', field_alias: 'work_date' },
          { field_text: '门店', field_alias: 'realname' },
          { field_text: '门店会员总数', field_alias: 'hierarchy_id_text' },
          { field_text: '新增会员', field_alias: 'rule_name' },
        ];
      },
    },
    rowList2: {
      type: Array,
      default() {
        return [
          { field_text: '序号', field_alias: 'index' },
          { field_text: '日期', field_alias: 'work_date' },
          { field_text: '门店', field_alias: 'realname' },
          { field_text: '门店会员总数', field_alias: 'hierarchy_id_text' },
          { field_text: '新增会员', field_alias: 'rule_name' },
        ];
      },
    },
  },
  // watch: {
  //   tableVisble: {
  //     handler(val) {
  //       if (val) {
  //         this.getList();
  //       }
  //     },
  //   },
  // },
  created() {
    this.rowList = this.rowList1;
    this.getList();
    if (this.tabs.length) {
      this.height = 660;
      this.type = this.tabs[0].name;
    }
  },
  methods: {
    handleClick() {
      this.page = 1;
      this.limit = 15;
      if (this.type === this.tabs[0].name) {
        this.rowList = this.rowList1;
      }
      if (this.type === this.tabs[1].name) {
        this.rowList = this.rowList2;
      }
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      this.exportLoading = true;
      let exportUrl;
      if (this.tabs.length) {
        if (this.type === this.tabs[0].name) {
          exportUrl = this.exportUrl;
        }
        if (this.type === this.tabs[1].name) {
          exportUrl = this.exportUrl2;
        }
      } else {
        exportUrl = this.exportUrl;
      }
      post({
        url: exportUrl,
      })
        .then((res) => {
          this.exportLoading = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `${this.title}.csv`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    // 分页页数改变
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 分页条数改变
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    getListReq(params) {
      let url;
      if (this.tabs.length) {
        if (this.type === this.tabs[0].name) {
          url = this.url;
        }
        if (this.type === this.tabs[1].name) {
          url = this.url2;
        }
      } else {
        url = this.url;
      }
      return post({
        url,
        data: {
          ...params,
        },
      });
    },
    getList() {
      this.loading = true;
      let time;
      if (this.time.field === 'times') {
        time = this.time.title;
      } else {
        time = this.time.field;
      }
      this.getListReq({ page: this.page, limit: this.limit, hierarchy_id: this.store.hierarchy_id, time })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$emit('onHide');
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__nav-wrap::after {
  content: '';
  height: 1px;
}
.tab {
  background: white;
  // border-radius: 6px;
  border: 1px solid #ddd;
  margin-top: 10px;
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  padding-left: 50px;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__content {
  background: #ddd;
}
/deep/ .el-tabs__item {
  font-size: 16px;
  height: 60px;
  line-height: 60px;
}
.table-wrapper {
  position: fixed;
  top: 74px;
  left: 90px;
  right: 10px;
  bottom: 0px;
  z-index: 9;
  background: #f6f7fb;
  .left {
    display: flex;
    cursor: pointer;
    margin-right: 40px;
    position: relative;
    align-items: center;
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 16px;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #DCDFE6;
    }
    .el-icon-back {
      font-size: 18px;
      margin-right: 6px;
      align-self: center;
    }
  }
  .right {
    font-size: 18px;
    color: #303133;
    display: flex;
    align-items: center;
    flex: 1;
    .tag {
      margin-left: 10px;
    }
  }
}
</style>
